<template>
  <div>
    <div class="mb-3 flex items-center justify-between border-b pb-3">
      <div>
        <h2 class="text-xl font-semibold">Thêm khí nhạc</h2>
      </div>
      <div class="">
        <router-link :to="{name: 'AdminInstrumentalList'}"
                     class="text-white hover:text-white hover:bg-green-700 bg-green-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center">
          Danh sách khí nhạc
        </router-link>
      </div>
    </div>
    <div class="lg:flex lg:space-x-10">
      <div class="lg:w-3/4">
        <div class="max-w-2xl m-auto shadow-md rounded-md bg-white mt-3">
          <div class="grid grid-cols-2 gap-3 lg:p-6 p-4">
            <div class="col-span-2">
              <label for="title" class="font-semibold">Tên khí nhạc (*)</label>
              <input v-model="instrumental.title" class="shadow-none with-border" id="title" type="text" autocomplete="off"
                     required>
            </div>

            <div class="col-span-2">
              <label for="content" class="font-semibold">Mô tả khí nhạc <i class="far fa-question-circle"
                                                                       :uk-tooltip="'Chỉ ghi lời khí nhạc, không ghi tên khí nhạc, người trình bày,...'"></i></label>
              <editor id="content" v-model:content="instrumental.content"/>
            </div>

            <div class="col-span-2">
              <label class="font-semibold">Trình bày <i class="far fa-question-circle"
                                                        :uk-tooltip="'Chọn người trình bày trong danh sách'"></i></label>
              <multiselect
                  v-model="artists"
                  mode="tags"
                  valueProp="id"
                  label="title"
                  trackBy="title"
                  placeholder="Gõ để tìm kiếm"
                  :closeOnSelect="true"
                  :filterResults="false"
                  :minChars="1"
                  :resolveOnLoad="false"
                  :delay="0"
                  :searchable="true"
                  :object="true"
                  :options="searchArtists"
              >
                <template v-slot:option="{ option }">
                  <img class="w-10 h-10 rounded-full object-cover mr-3"
                       :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                    option.title
                  }}
                  {{ option.yob ? ' (' + option.yob + ')' : '' }}
                </template>

                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                  <div class="multiselect-tag is-user">
                    <img :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"
                         class="w-6 h-6 rounded-full object-cover mr-3">
                    {{ option.title }}
                    <span
                        v-if="!disabled"
                        class="multiselect-tag-remove"
                        @mousedown.prevent="handleTagRemove(option, $event)"
                    >
                  <span class="multiselect-tag-remove-icon"></span>
                </span>
                  </div>
                </template>
              </multiselect>
            </div>

            <div class="col-span-2">
              <label for="record_year" class="font-semibold">Năm thu âm</label>
              <input v-model="instrumental.record_year" class="shadow-none with-border" id="record_year" type="text"
                     autocomplete="off">
            </div>

            <div class="col-span-2">
              <label for="subtitle" class="font-semibold">Ghi chú</label>
              <input v-model="instrumental.subtitle" class="shadow-none with-border" id="subtitle" type="text"
                     autocomplete="off">
            </div>
          </div>
        </div>
      </div>

      <div class="lg:w-1/4 w-full">
        <div>
          <label class="font-semibold">File nhạc (*)</label>
          <DropZone type="music" v-on:uploadDone="handleUploadDone" v-model:objectIdProp="instrumental.file_id"/>
        </div>
        
        <div class="mt-4" v-if="instrumental.file && instrumental.file.type === 'video'">
          <label class="font-semibold">Kiểu hiển thị nhạc</label>
          <select v-model="instrumental.file_type">
            <option value="audio">Audio</option>
            <option value="video">Video</option>
          </select>
        </div>

        <div class="mt-4">
          <label class="font-semibold">Ảnh minh họa</label>
          <DropZone type="image" v-model:objectIdProp="instrumental.image_id"/>
        </div>
        
        <div class="mt-4">
          <label class="font-semibold">Thể loại</label>
          <select v-model="instrumental.type">
            <option value="morden">Khí nhạc Việt Nam</option>
            <option value="classical">Khí nhạc nước ngoài</option>
          </select>
        </div>

        <div class="mt-4">
          <label class="font-semibold">Đóng góp</label>
          <multiselect
              v-model="uploader"
              valueProp="id"
              label="username"
              trackBy="username"
              placeholder="Gõ để tìm kiếm"
              :closeOnSelect="true"
              :filterResults="false"
              :minChars="1"
              :resolveOnLoad="false"
              :delay="0"
              :searchable="true"
              :object="true"
              :options="searchUsers"
          >
            <template v-slot:option="{ option }">
              <img class="w-10 h-10 rounded-full object-cover mr-3"
                   :src="option.avatar ? option.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                option.username
              }}
            </template>

            <template v-slot:singlelabel="{ value }">
              <div class="multiselect-single-label">
                <img class="w-7 h-7 rounded-full object-cover mr-3"
                     :src="value.avatar ? value.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-2.jpg'"> {{
                  value.username
                }}
              </div>
            </template>
          </multiselect>
        </div>

        <div class="mt-4">
          <label class="font-semibold">Bản nhạc liên kết</label>
          <multiselect
              v-model="sheet"
              valueProp="id"
              label="title"
              trackBy="title"
              placeholder="Gõ để tìm kiếm"
              :closeOnSelect="true"
              :filterResults="false"
              :minChars="1"
              :resolveOnLoad="false"
              :delay="0"
              :searchable="true"
              :object="true"
              :options="searchSheets"
          >
            <template v-slot:option="{ option }">
              {{ option.title }}
              {{ option.year ? ' (' + option.year + ') ' : '' }}
              {{ option.composers && option.composers.data ? ' - ' + getPeopleNames(option.composers.data) : ''}}
            </template>

            <template v-slot:singlelabel="{ value }">
              <div class="multiselect-single-label">
                {{ value.title }}
                {{ value.year ? ' (' + value.year + ') ' : '' }}
                {{ value.composers && value.composers.data ? ' - ' + getPeopleNames(value.composers.data) : ''}}
              </div>
            </template>
          </multiselect>
          <div v-if="sheet.id" class="mt-4">
            <router-link class="mr-2" :to="{name: 'AdminSheetEdit', params: {id: sheet.id}}">Sửa bản nhạc</router-link>
          </div>
        </div>

        <div class="mt-7">
          <button type="button"
                  class="text-white hover:text-white hover:bg-green-700 bg-green-600 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
                  v-on:click="createInstrumental">
            Thêm mới
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
input.multiselect-tags-search {
  height: auto !important;
}
</style>

<script>
import Multiselect from '@vueform/multiselect'
import DropZone from "@/components/DropZone.vue";
import Editor from "../../Editor";
import {canManageSong, changePageTitle, getPeopleNames} from "../../../core/services/utils.service";
import ApiService from "../../../core/services/api.service";

export default {
  title: "AdminInstrumentalAdd",
  components: {
    Editor,
    Multiselect,
    DropZone
  },
  data() {
    return {
      instrumental: {
        title: "",
        content: "",
        subtitle: "",
        year: "",
        record_year: "",
        file_type: "audio",
        file_id: null,
        image_id: null,
        sheet_id: null,
        type: "morden",
        file: {
          type: "audio"
        }
      },
      uploader: {
        id: null
      },
      sheet: {
        id: null,
        slug: ''
      },
      artists: []
    }
  },
  methods: {
    createInstrumental() {
      if (!this.instrumental.title) {
        this.$toast.error("Bạn chưa nhập tên khí nhạc");
        return;
      }
      
      if (!this.instrumental.file_id) {
        this.$toast.error("Bạn chưa chọn file nhạc");
        return;
      }

      this.disabled = true;
      this.message = "";

      let query = `mutation($title: String!, $content: String, $subtitle: String, $year: String, $record_year: String, $file_type: String, $file_id: ID, $image_id: ID, $sheet_id: ID, $uploader_id: ID, $type: String, $sync_instrumental_artist: [SyncInstrumentalArtist]) {
        createInstrumental(input: {
          title: $title,
          content: $content
          subtitle: $subtitle
          year: $year
          record_year: $record_year
          file_type: $file_type
          file_id: $file_id
          image_id: $image_id
          sheet_id: $sheet_id
          uploader_id: $uploader_id
          type: $type
          artists: {
            sync: $sync_instrumental_artist
          }
        }) {
          id
        }
      }`;

      let sync_instrumental_artist = [];

      this.artists.map((item, index) => {
        sync_instrumental_artist.push({id: item.id, order: index});
      });

      ApiService.graphql(query, {
        title: this.instrumental.title,
        content: this.instrumental.content,
        subtitle: this.instrumental.subtitle,
        year: this.instrumental.year,
        record_year: this.instrumental.record_year,
        file_type: this.instrumental.file_type,
        file_id: this.instrumental.file_id,
        image_id: this.instrumental.image_id,
        sheet_id: this.sheet ? this.sheet.id : null,
        type: this.instrumental.type,
        uploader_id: this.uploader ? this.uploader.id : null,
        sync_instrumental_artist: sync_instrumental_artist
      })
          .then(({data}) => {
            if (data.data && data.data.createInstrumental) {
              this.$router.push({name: "AdminInstrumentalList"});
              this.$toast.success("Thêm khí nhạc thành công");
            } else {
              this.$toast.error(data.errors[0].message);
            }
          })
          .catch((response) => {
            this.$toast.error(response.message);
          });
    },
    async searchArtists(q) {
      let query = `query($q: Mixed) {
        artists(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "title", operator: LIKE, value: $q}]}) {
          data {
            id
            title
            yob
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.artists && data.data.artists.data) {
              return data.data.artists.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    async searchUsers(q) {
      let query = `query($q: Mixed) {
        users(first: 10, orderBy: [{column: "views", order: DESC}], where: {AND: [{column: "username", operator: LIKE, value: $q}]}) {
          data {
            id
            username
            avatar {
              url
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `${q}%`})
          .then(({data}) => {
            if (data.data && data.data.users && data.data.users.data) {
              return data.data.users.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    async searchSheets(q) {
      let query = `query($q: Mixed, $id: Mixed) {
        sheets(first: 50, orderBy: [{column: "id", order: DESC}], where: {OR: [{column: "title", operator: LIKE, value: $q}, {column: "id", value: $id}]}) {
          data {
            id
            title
            year
            composers(first: 20) {
              data {
                id
                title
                slug
              }
            }
          }
        }
      }`;

      return await ApiService.graphql(query, {q: `%${q}%`, id: q})
          .then(({data}) => {
            if (data.data && data.data.sheets && data.data.sheets.data) {
              return data.data.sheets.data;
            } else {
              return [];
            }
          })
          .catch((response) => {
            console.log(response);
            return [];
          });
    },
    handleUploadDone(data) {
      if (data && data.type) {
        this.instrumental.file_type = data.type;
        this.instrumental.file.type = data.type;
      }
    },
    getPeopleNames(x) {
      return getPeopleNames(x);
    }
  },
  computed: {
    canManageSong() {
      return canManageSong();
    }
  },
  mounted() {
    if (!canManageSong()) {
      this.$router.push({name: "Home"});
    }

    changePageTitle("Thêm khí nhạc");
  }
}
</script>
